function selectDate(n) {
  var item = document.getElementsByClassName("date-list-item");
  var page = document.getElementsByClassName("page");

  for (var i = 0; i < 5; i++) {
    item[i].classList.remove("active");
    page[i].classList.remove("active");
  }
  item[n].classList.add("active");
  page[n].classList.add("active");
  changeDate(n);
}

function changeDate(n) {
  var date = [
    {
      title2: "Saint-Pierre de<br/> Charenton-le-Pont",
      title1: "",
      text2: `Les équipes du <span class="font-italic">Jour du Seigneur</span> vous offriront une belle messe pour ce premier dimanche de l’Avent en l’église Saint-Pierre de Charenton-le-Pont, dans le Val-de-Marne, avec la communauté de Sant’Egidio. Le prédicateur sera le frère Marc-Antoine Bêchétoille, dominicain, et le président sera le père Jérôme Thuault, curé de cette paroisse et prêtre de Sant‘Egidio. Cette communauté est une sentinelle de lumière qui œuvre pour la paix en la protégeant là où elle est menacée, en aidant à la reconstruire et en facilitant le dialogue quand il s’est arrêté.`,
      credit: "© Catherine Pic",
    },
    {
      title2: "Sainte-Lucie<br/> à Issy-les-Moulineaux",
      title1: "",
      text2: `C’est en l’église Sainte-Lucie, à Issy-les-Moulineaux, 
      avec une paroisse familiale et joyeuse que 
      nous allons célébrer en direct la messe du deuxième 
      dimanche de l’Avent.`,
      credit: "©shutterstock/Kim Norrman",
    },
    {
      title2: "Basilique Sainte-Jeanne-d’Arc à Paris",
      title1: "",
      text2: `Le 17 décembre est le Dimanche de la joie, ou 
« Réjouis-toi ». La messe sera célébrée en la basilique 
Sainte-Jeanne-d’Arc de Paris. C’est un autre lieu de 
dévotion populaire de la capitale. Il est animé par 
la communauté du Chemin neuf, porteuse d’une foi 
joyeuse avec une ouverture œcuménique. Le prédicateur 
sera le père Yves Combeau, tandis que le président sera 
le père Hervé Loua, curé de la paroisse 
Saint-Denys de la Chapelle.`,
      credit: "©Sophie Delay",
    },
    {
      title2: "Cathédrale Saint-Pierre<br/>de Montpellier",
      title1: "",
      text2: `La messe de ce 4<sup>e</sup> dimanche de l’Avent sera célébrée à 11h en la 
cathédrale Saint-Pierre de Montpellier. Le frère Thierry Hubert sera 
heureux de prêcher lors de cette célébration dont le président sera 
le chanoine Michel Plagniol, archiprêtre et curé de cette cathédrale. 
Le soir, <span class="font-italic">Le Jour du Seigneur</span> sera fier de diffuser sa 75<sup>e</sup> messe de 
minuit. Elle sera bien sûr célébrée en la basilique Saint-Pierre de 
Rome et prêchée par notre pape François.`,
      credit: "©monuments historiques/J-F Peire, 2017",
    },
    {
      title2: "Cathédrale Saint-Pierre <br/>de Montpellier",
      title1: "",

      text2: `Ce lundi 25 décembre, la messe de Noël sera célébrée en direct de la cathédrale Saint-Pierre de Montpellier avec M<sup>gr</sup> Norbert Turini, archevêque de Montpellier, avant de retrouver la traditionnelle bénédiction <span class="font-italic">Urbi et orbi</span> du pape François à Rome.`,
      credit: "©Georges de la Tour/Bridgeman Images",
    },
  ];

  var bg = document.getElementsByClassName("date")[0];
  var title2 = document.getElementsByClassName("date-content-title2")[0];
  var title1 = document.getElementsByClassName("date-content-title1")[0];
  var title1a = document.getElementsByClassName("date-content-title1")[1];
  var text2 = document.getElementsByClassName("date-content-text2")[0];
  var credit = document.getElementsByClassName("date-credit")[0];
  var i = n + 1;

  if (document.documentElement.clientWidth < 500)
    bg.style.backgroundImage = "url('dist/asset/img/date-bg" + i + "-mb.jpg')";
  else bg.style.backgroundImage = "url('dist/asset/img/date-bg" + i + ".jpg')";
  title2.innerHTML = date[n]["title2"];
  title1.innerHTML = date[n]["title1"];
  title1a.innerHTML = date[n]["title1"];
  text2.innerHTML = date[n]["text2"];
  credit.innerHTML = date[n]["credit"];
}

function resize() {
  var bg = document.getElementById("date");
  var page = document.getElementsByClassName("page");

  var n;

  for (var i = 0; i < 5; i++) if (page[i].classList.contains("active")) n = i;
  if (document.documentElement.clientWidth < 500) {
    bg.setAttribute(
      "background-image",
      "url('dist/asset/img/date-bg" + n + "-mb.jpg')"
    );
  } else {
    bg.setAttribute(
      "background-image",
      "url('dist/asset/img/date-bg" + n + ".jpg')"
    );
  }

  const vw = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  );
  const vh = Math.max(
    document.documentElement.clientHeight || 0,
    window.innerHeight || 0
  );
  var navbar = document.getElementById("navigation");
  var header = document.getElementById("header");

  header.style.marginTop = navbar.offsetHeight + "px";
  header.style.height = vh - navbar.offsetHeight + "px";
}

var currentDate = new Date().toJSON().slice(0, 10);
var check = new Date(currentDate);
var start = new Date("2000/01/01");
var d1 = new Date("2023/12/03");
var d2 = new Date("2023/12/10");
var d3 = new Date("2023/12/17");
var d4 = new Date("2023/12/24");
var d5 = new Date("2023/12/25");

if (check >= start && check <= d1) {
  selectDate(0);
}
if (check > d1 && check <= d2) {
  selectDate(1);
}
if (check > d2 && check <= d3) {
  selectDate(2);
}
if (check > d3 && check <= d4) {
  selectDate(3);
}
if (check > d4 && check <= d5) {
  selectDate(4);
}
if (check >= d5) {
  selectDate(4);
}

document.getElementsByClassName("player")[0].style.display = "none";

function togglePlayer() {
  let player = document.getElementsByClassName("player")[0];
  let btn = document.getElementsByClassName("play")[0];
  let iframe = document.getElementById("iframe");
  if (!player || !btn || !iframe) return;
  if (player.style.display === "none") {
    player.style.display = "flex";
    btn.style.display = "none";
    iframe.src = "https://www.youtube-nocookie.com/embed/pVlfnM0k5m4?si=4arQ5u5gpb78rj7o"
  } else {
    btn.style.display = "inline-block";
    player.style.display = "none";
    iframe.src = ""
  }
}
