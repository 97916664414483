window.addEventListener("load", updateParam());

function updateParam() {
  var btn = document.getElementsByTagName("a");
  for (let i = 0; i !== btn.length; i++) {
    btn[i].href = passUtmParamsToLink(btn[i].href);
  }
}

setTimeout(updateParam(), 1000);

function passUtmParamsToLink(base) {
  const url = new URL(base);
  const params = new URLSearchParams(window.location.search);
  params.forEach((value, key) => {
    if (
      key === "utm_campaign" ||
      key === "utm_source" ||
      key === "utm_medium" ||
      key === "reserved_code_media"
    ) {
      url.searchParams.set(key, value);
    }
  });
  return url.href;
}
